import React, { Component, Suspense, useState } from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss';
import 'src/assets/css/custom.css';
// import { store, persistor } from "./redux/store/store";
// import { Provider, useSelector } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
import LoaderPage from './views/loader/loaderPage';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Loader = React.lazy(() => import('./views/loader/loaderPage'))
const NotFound = React.lazy(() => import('./views/loader/notFound'))

class App extends Component {

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isLoggedIn: false
  //   };
  // }

  // async componentDidMount() {
  //   const value = await AsyncStorage.getItem('persist:root');
  //   const bvalue = JSON.parse(value);
  //   const userData = bvalue && bvalue.LoginReducer?  JSON.parse(bvalue.LoginReducer) : null;
  //   this.setState({ isLoggedIn: Boolean(userData && userData.data) });
  // }

  // isAuthenticated(){
  //   const localData = localStorage.getItem('persist:root');
  //   const localUserData = JSON.parse(localData);
  //   const localLoginData = localUserData.LoginReducer;
  //   const localLoginUserData = JSON.parse(localLoginData);
  //   const ifUserLogged = Boolean(localLoginUserData.data != null);
  //   console.log("If user logged: ", ifUserLogged);
  //   return ifUserLogged;
  //   // const value = await AsyncStorage.getItem('persist:root');
  //   // const bvalue = JSON.parse(value);
  //   // const userData = bvalue && bvalue.LoginReducer?  JSON.parse(bvalue.LoginReducer) : null;
  //   // const isLoggedIn = Boolean(userData && userData.data);
  //   // console.log("is logged in: ", isLoggedIn);
  //   // return isLoggedIn;
  // }

  render() {
    return (
      // <Provider store={store}>
      //   <PersistGate loading={null} persistor={persistor}>
          <HashRouter>
            <Suspense fallback={loading}>
              <Routes>
                <Route exact path="/login" name="Login Page" element={<Login/>} />
                <Route exact path="/register" name="Register Page" element={<Register />} />
                <Route exact path="/404" name="Page 404" element={<Page404 />} />
                <Route exact path="/500" name="Page 500" element={<Page500 />} />
                <Route exact path="/home" name="Home" element={<Loader/>} />
                <Route path="/" element={<LoaderPage/>} />
                <Route path="*" name="Home" element={<DefaultLayout/>} />
              </Routes>
            </Suspense>
          </HashRouter>
      //   </PersistGate>
      // </Provider>
    )
  }
}

export default App
